body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#browse-files {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#browse-files-label {
  background-color: rgba(16, 16, 16, 0.975);
  color: #ffffff;
  border: 2px solid rgba(16, 16, 16, 0.975);
  border-radius: 8px;
  width: 350px;
  margin: auto;
}

#browse-files-label:hover {
  opacity: 0.5;
}
